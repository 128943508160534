import "./App.css";
import React, { Suspense } from "react";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import ScrollToTopSVG from "./Component/ScrollToTop/ScrollToTop";
import useEduJs from "./Hooks/useEduJs1";
import "./styles/marquee.scss";
import "./styles/upload.scss";
import "./styles/herocard.scss";
import "./styles/customdropdown.scss";
import "./styles/animatedbackground.scss";
import "./styles/calendar.scss";
import "./styles/registration.scss";
import "./styles/common.scss";
import "./styles/custombutton.scss";
import "./styles/querybutton.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DisplayCourse from "./Component/DisplayCourse/DisplayCourse";
import Home from "./Component/Home/Home";
import Coming_Soon from "./Component/Coming Soon/Coming_Soon";
import DisplayBatch from "./Component/DisplayBatch/DisplayBatch";
import Registration from "./Component/Form/Registration";
import { ButtonDetailsProvider } from "./Component/Context/Pagebuttonmanagement";
import { BranchProvider } from "./Component/Context/Branchapi";
import { BackgroundProvider } from "./Component/Context/BackgroundChange";
import { BlogsProvider } from "./Component/Context/Blogsapi";
import { CourseProvider } from "./Component/Context/Courseapi";
import { ArticleProvider } from "./Component/Context/Articleapi";
import BranchPage from "./Component/Pages/BranchPage";
import { DateProvider } from "./Component/Context/Dateapi";
import { WebdetailsProvider } from "./Component/Context/WebDetails";
import { NewsheadlineProvider } from "./Component/Context/NewsHeadline";
import { SeoProvider } from "./Component/Context/SEOManagement";
import { HelmetProvider } from 'react-helmet-async';
import { ContentProvider } from "./Component/Context/ContentManagement";
import { ToggleProvider } from "./Component/Context/Togglemanagement";
import { ScrollingManagementProvider } from "./Component/Context/ScrollingEventManagement";
import Testdetails from "./Component/Testdetails/Testdetails";
import { TestProvider } from "./Component/Context/Testpi";
import { QueryProvider } from "./Component/Context/QueryManagement";
import { AboutProvider } from "./Component/Context/Aboutapi";
// import CustomRegistration from "./Component/Form/CustomRegistration";
import ButtonDetailsWithBorder from "./Component/ButtonDetails/ButtonDetailsWithBorder";
import Allcourselist from "./Component/Courses/Allcourselist";
import Courselist from "./Component/Card/Courselist";
import Php from "./Component/PhpComponent/php";
import { FooterProvider } from "./Component/Context/footerapi";
import { PageProvider } from "./Component/Context/Pageapi";
import { HomeProvider } from "./Component/Context/Homeapi";
import { InterviewProvider } from "./Component/Context/Interviewapi";
import { BranchwiseidProvider } from "./Component/Context/BranchwiseCourse";
import { ExplorationProvider } from "./Component/Context/Explorationapi";
import { PackageProvider } from "./Component/Context/packagemanagement";
import Preloader from "./Component/Preloader/Preloader";
import TestimonialDetails from "./Component/TestimonialDetails/TestimonialDetails";
import FloatingButton from "./Component/FloatingButton";
// import Contactpage from "./Component/Contactpage/Contactpage";
// import Exploredetails from "./Component/ExploreDetails/ExploreDetails";
// import Exploration from "./Component/Exploration/Exploration";
// import Interview from "./Component/Interview/Interview";
// import PageComponent from "./Component/PageComponent/PageComponent";
// import { BranchProvider } from './Component/Context/Branchapi';
const  Ourbranch = React.lazy(() => import("./Component/Ourbranch/Ourbranch"));
const  Coursedetails = React.lazy(() => import("./Component/Coursedetails/Coursedetails"));
const  Blogs = React.lazy(() => import("./Component/Blogs/Blogs"));
const  Interview = React.lazy(() => import("./Component/Interview/Interview"));
const  Articles = React.lazy(() => import("./Component/Articles/Articles"));
const  BlogDetails = React.lazy(() => import("./Component/BlogDetails/BlogDetails1"));
const  ArticleDetails = React.lazy(() => import("./Component/ArticleDetails/ArticlesDetails"));
const  AboutPage = React.lazy(() => import("./Component/AboutPage/AboutPage"));
const  PageComponent = React.lazy(() => import("./Component/PageComponent/PageComponent"));
const  PageComponent1 = React.lazy(() => import("./Component/PageComponent/PageComponent1"));
const  Exploration = React.lazy(() => import("./Component/Exploration/Exploration"));
const  Exploredetails = React.lazy(() => import("./Component/ExploreDetails/ExploreDetails"));
const  CustomRegistration = React.lazy(() => import("./Component/Form/CustomRegistration"));
const  Contactpage = React.lazy(() => import("./Component/Contactpage/Contactpage"));
function App() {
  useEduJs();

  return (
    <>
    <HelmetProvider>
      <QueryProvider>
      <BranchwiseidProvider>
    <SeoProvider>
        <WebdetailsProvider>
        <ScrollingManagementProvider>
      <BackgroundProvider>
        <BranchProvider>
        <CourseProvider>
        <BrowserRouter>
          
        <NewsheadlineProvider>
        <ToggleProvider>
          <Header />
        </ToggleProvider>
          </NewsheadlineProvider>
          <Routes>
            <Route path="/" element={
          <>
          <HomeProvider>
          <Home />
          </HomeProvider>
          </>  
          } 
              />
            <Route path="/php" element={<Php />} />
            <Route path="/check" element={<Courselist />} />
            <Route path="/testimonial-details" element={<TestimonialDetails />} />
            {/* <Route path="/testimonial-details" element={<FloatingButton />} /> */}
            <Route path="/branches" element={<BranchPage />} />
            <Route path="/about/:abouturl" element={
<>
<BackgroundProvider>
                    <AboutProvider>
                    <Suspense fallback={<><Preloader/></>}>
                      <AboutPage />
                      </Suspense>
                    </AboutProvider>
                  </BackgroundProvider>
</>
            } />
            <Route path="/upsc/:pageurl" element={
<>
<BackgroundProvider>
                    <PageProvider>
                    <Suspense fallback={<><Preloader/></>}>
                      <PageComponent1 />
                      </Suspense>
                    </PageProvider>
                  </BackgroundProvider>
</>
            } />
            <Route path="/upsc/referal-policy" element={
<>
<BackgroundProvider>
                    <PageProvider>
                    <Suspense fallback={<p>Loading...........</p>}>
                      <PageComponent />
                      </Suspense>
                    </PageProvider>
                  </BackgroundProvider>
</>
            } />
            {/* <Route path="/Course" element={
            <>
            <BackgroundProvider>
            <DisplayCourse />
            </BackgroundProvider>
            
            </>
            
            
            } /> */}
            {/* <Route path="/Course-details" element={
            
            <Coursedetails />
            
            } />  */}
            <Route
              path="/blog/:blogurl"
              element={
                <>
                <BackgroundProvider>
                <BlogsProvider>
                <Suspense fallback={<p>Loading........</p>}>
                  <BlogDetails />
                  </Suspense>
                  </BlogsProvider>
                </BackgroundProvider>
                </>
              }
            />
            <Route
              path="/explore/:exploreurl"
              element={
                <>
                <BackgroundProvider>
                <ExplorationProvider>
                <Suspense fallback={<p>Loading..........</p>}>
                  <Exploredetails />
                  </Suspense>
                  </ExplorationProvider>
                </BackgroundProvider>
                </>
              }
            />
            <Route
             exact path="/upsc-daily-current-affairs/:articleurl/"
              element={
                <>
                <BackgroundProvider>
                <ArticleProvider>
                <Suspense fallback={<p>Loading.............</p>}>
                  <ArticleDetails />
                  </Suspense>
                  </ArticleProvider>
                </BackgroundProvider>
                </>
              }
            />
            <Route
              path="/upsc-daily-current-affair"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <ArticleProvider>
                    <Suspense fallback={<p>Loading...............</p>}>
                      <Articles />
                      </Suspense>
                    </ArticleProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            />
            <Route
              path="/blogs"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <BlogsProvider>
                    <Suspense fallback={<p>Loading..................</p>}>
                      <Blogs /> 
                      </Suspense>
                    </BlogsProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            /> 
            <Route
              path="/interview-guidance-program"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <InterviewProvider>
                    <Suspense fallback={<p>Loading.....................</p>}>
                      <Interview /> 
                      </Suspense>
                    </InterviewProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            /> 
            <Route
              path="/exploration"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <ExplorationProvider>
                    <Suspense fallback={<p>Loading.....................</p>}>
                      <Exploration /> 
                      </Suspense>
                    </ExplorationProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            /> 
            {/* <Route path="/batch/:url" element={<Coursedetails />} /> */}
            <Route
              path="/course"
              element={
                  <DisplayCourse />
              }
            />
            <Route
              path="/Course-details/:courseurl"
              element={ 
                <>
                <ContentProvider>
                  <ButtonDetailsProvider>
                    <BackgroundProvider>
                      <CourseProvider>
                      <Suspense fallback={<p>Loading.....................</p>}>
                        <Coursedetails />
                        </Suspense>
                      </CourseProvider>
                    </BackgroundProvider>
                  </ButtonDetailsProvider>
                  </ContentProvider>
                </>
              }
            />
            <Route
              path="/test-series/:testurl"
              element={
                <>
                <ContentProvider>
                  <ButtonDetailsProvider>
                    <BackgroundProvider>
                      <TestProvider>
                      <Suspense fallback={<p>Loading.....................</p>}>
                        <Testdetails />
                        </Suspense>
                      </TestProvider>
                    </BackgroundProvider>
                  </ButtonDetailsProvider>
                  </ContentProvider>
                </>
              }
            />
            <Route
              path="/branch/:branchUrl"
              element={
                <>
                                                <ButtonDetailsProvider>
                  <BackgroundProvider>
                    <BranchProvider>
                    <Suspense fallback={<p>Loading.....................</p>}>
                      <Ourbranch />
                      </Suspense>
                    </BranchProvider>
                  </BackgroundProvider>
                  </ButtonDetailsProvider>
                </>
              }
            />
            <Route
              path="/contactus"
              element={
                <>
                                                <ButtonDetailsProvider>
                  <BackgroundProvider>
                    <BranchProvider>
                    <Suspense fallback={<p>Loading.....................</p>}>
                      <Contactpage />
                      </Suspense>
                    </BranchProvider>
                  </BackgroundProvider>
                  </ButtonDetailsProvider>
                </>
              }
            />
            <Route
              path="/batch-details"
              element={
                <>
                  <ButtonDetailsProvider>
                    <DisplayBatch />
                  </ButtonDetailsProvider>
                </>
              }
            />
            <Route path="/*" element={<Coming_Soon />} />
            {/* <Route
              path="/our-branch"
              element={
                <>
                  <BranchProvider>
                    <Ourbranch />
                  </BranchProvider>
                </>
              }
            /> */}
            <Route path="/registration" element={
              <>
              <PackageProvider>
                                <ButtonDetailsProvider>
                                <Suspense fallback={<p>Loading.....................</p>}>
              <CustomRegistration />
              </Suspense>
                                </ButtonDetailsProvider>
                                </PackageProvider>
              </>
              } />
            {/* <Route path="/date" element={<Datepicker />} /> */}
          </Routes>
          <FooterProvider>
          <Footer />
          </FooterProvider>
          <ScrollToTopSVG />
        </BrowserRouter>
        </CourseProvider>
        </BranchProvider>        
      </BackgroundProvider>
        </ScrollingManagementProvider>
        </WebdetailsProvider>
        </SeoProvider>
        </BranchwiseidProvider>
        </QueryProvider>
        </HelmetProvider>
    </>
  );
}

export default App;
