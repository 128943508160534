// services/api.js
import axios from 'axios';
import API_BASE_URL from './baseurl';

// Function to fetch all branches
export const fetchAllBranches = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all branches:", error);
        throw error;
    }
};

// Function to fetch branch details by URL
export const fetchBranchByUrl = async (branchUrl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches/${branchUrl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch by URL:", error);
        throw error;
    }
};


// Function to fetch all Courses
export const fetchAllCourses = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all branches:", error);
        throw error;
    }
};

// Function to fetch all blogs
export const fetchAllBlogs = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch More blogs
export const fetchMoreBlogs = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch Course details by URL
export const fetchCourseByUrl = async (courseurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${courseurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch by URL:", error);
        throw error;
    }
};

// Function to fetch Course details by URL
export const fetchCourseByUrlBrach = async (courseurl,id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${courseurl}/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch by URL:", error);
        throw error;
    }
};

// Function to fetch all blogs
export const fetchBlogByUrl = async (blogurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blog/${blogurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all article
export const fetchAllArticles = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch more Article
export const fetchMoreArticles = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all blogs
export const fetchArticlesByUrl = async (articleurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affairs/${articleurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}


// Function to fetch all blogs using Date
export const fetchAllBlogsDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch More blogs using Date
export const fetchMoreBlogsDate = async (s1,e1,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${s1}/${e1}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all article using date
export const fetchAllArticlesDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all article using date
export const fetchMoreArticlesDate = async (s1,e1,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${s1}/${e1}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all webdetails
export const fetchAllWebdetails = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/webdetails`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Scrolling Header Data
export const fetchAllNewsheadline = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/newsheadline`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Hero Bnnnaer Data
export const fetchHeroBnaner = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/headerbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Navbar Details Data
export const fetchNavbarDetails = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/topnavbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to footer left Data
export const fetchfooterleft = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/leftfooterbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to footer right Data
export const fetchfooterright = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/rightfooterbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Referal Policy Data
export const fetchPageByurl = async (pageurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc/${pageurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to About details Data for Home Page
export const fetchAboutsecctionbyHomepage = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/abouthome`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Testimonials Data
export const fetchTestimonials = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/testimonials`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to faculty right Data
export const fetchfaculty = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faculty`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Home Content Data
export const fetchHomepageContent = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/indexpagecontent`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to faculty right Data
export const fetchfaq = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faq`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Interview Content Data
export const fetchInterviewContent = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/interviewcontent`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Interview Panel Data
export const fetchInterviewPanel = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/interviewpanel`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Exploreation Data
export const fetchExploreation = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/exploration`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to Exploreation details by URL
export const fetchExploreationByUrl = async (exploreurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/explore/${exploreurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch Test details by URL
export const fetchTestByUrl = async (testurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${testurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch About details by URL
export const fetchAboutByUrl = async (abouturl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/about/${abouturl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to submit data using POST API
export const submitQueryForm = async (name, contact, email, query, pagename, pagurl) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/queryform`, {
            name,
            contact,
            email,
            query,
            pagename,
            pagurl,
        });
        return response.data;
    } catch (error) {
        console.error("Error submitting contact form:", error);
        throw error;
    }
};


