import React, { useEffect , Suspense  } from 'react';
import  blog_data from '../../Data/blog-data';
import { useBranchContext } from '../Context/Branchapi';
const  Batchcard = React.lazy(() => import("../Batchcard/Batchcard"));

function Batch() {
    const { branches, loading, error, fetchAllBranchesHandler } = useBranchContext();

  useEffect(() => {
    fetchAllBranchesHandler(); // Fetch branches on component mount
  }, []);
    const blogItems = blog_data.filter(b => b.home_1); 
  return (
    <>
    <div className="edu-blog-area blog-area-1 edu-section-gap bg-lightenharsh">
            <div className="container">
                <div className="section-title section-center">
                    <span className="pre-title">OUR BRANCHES</span>
                    <h2 className="title">Study with SRIRAM's IAS</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5">
                    {/* <!-- Start Blog Grid  --> */}
                    {branches.map((blogItem, i) => ( 
                        <div key={i} className="col-lg-3 col-md-6 col-12">
                            <Suspense fallback={<p>This is Loading. branch</p>}>
                            <Batchcard param={blogItem}/>
                            </Suspense>
                        </div> 
                    ))} 
                    {/* <!-- End Blog Grid  --> */}
                    </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1 scene">
                    <img data-depth="-1.4" src="assets/images/about/shape-02.png" alt="Shape"/>
                </li>
                <li className="shape-2 scene">
                    <span data-depth="2.5"></span>
                </li>
                <li className="shape-3 scene">
                    <img data-depth="-2.3" src="assets/images/counterup/shape-05.png" alt="Shape"/>
                </li>
            </ul>
        </div>
    
    
    </>
  )
}

export default Batch