import React, { useEffect } from "react";
import courseimg from "../../assets/images/course/course-111.jpg";
import imgcourse from "../../assets/images/blog/blog-01.png";
import { useCourseContext } from "../Context/Courseapi";
import { Link } from "react-router-dom";
import { useScrollingManagementContext } from "../Context/ScrollingEventManagement";

function Branch() {
   const {courses, loading, error, fetchAllCoursesHandler} = useCourseContext();
   const { ScrollingopenHandler } = useScrollingManagementContext();

   useEffect(() => {
    fetchAllCoursesHandler(); // Fetch branches on component mount
  }, []);

  // if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="section-title section-center">
        <span className="pre-title">Popular Course</span>
        <h2 className="title">Pick A Course To Get Started</h2>
        <span className="shape-line">
          <i className="icon-19"></i>
        </span>
      </div>
      <div className="row g-5">
      {courses.map((course) => (
<Link to={`/course-details/${course.courseurl}`} onClick={()=>ScrollingopenHandler()} className="col-12 col-xl-4 my-2 p-4 col-lg-6 col-md-6">
                        <div class="edu-course course-style-3 course-box-shadow">
                            <div class="inner">
                                <div class="thumbnail">
                                <Link to={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} onError={(e) => {
                                        e.target.src = imgcourse;
                                    }} alt="Course Meta"/>
                  </Link>
                  <div class="time-top">
                                        <span class="duration"><i class="icon-61"></i>{course.duration}</span>
                                    </div>
                                </div>
                                <div class="content custom-content">
                                  <div className='d-flex flex-wrap align-items-start' style={{minHeight: "100px"}}>
                                    <div className="d-flex">

                                <span class="course-level custom-badge d-flex justify-content-center align-items-center py-2">{course.cname}</span>
                                    </div>
                                <div className="d-flex flex-grow-1 align-items-center">
                                {course.branchnames.map((name,index)=>(
                                <span class="course-level d-flex fs-6 justify-content-center align-items-center py-2 ms-2" style={{whiteSpace: "nowrap"}}>{name}</span>
                                ))}&nbsp;
                                </div>
                                  </div>
<Link to={`/course-details/${course.courseurl}`}><span className="course-level fw-bold fs-3 px-0 py-2 pb-0" style={{backgroundColor: "transparent", height: "45px", fontFamily: "Arial, sans-serif",  color: "#000000"  }}>{course.coursename}</span></Link>
<p className="custom-paragraph1 text-dark" style={{textAlign: "justify", marginBottom: "", lineHeight: "4vh", fontFamily: "Arial, sans-serif"}}>{course.metadescription}</p>
                                   
                                    <div className="course-rating m-0">
                                        <div className="rating">
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                        </div>
                                    </div>
                                    <div class="read-more-btn">
                                    <ul className="course-meta mt-0 d-flex justify-content-end">
                    <li>
                    <Link class="edu-btn btn-small btn-secondary" to={`/course-details/${course.courseurl}`}>View Details<i class="icon-4 text-white"></i></Link>
</li>
                  </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
))}
      </div>
    </>
  );
}

export default Branch;
