import React, { useEffect, useState } from "react";
import h1 from "../../assets/images/animated-svg-icons/online-class.svg"
import h2 from "../../assets/images/animated-svg-icons/instructor.svg"
import h3 from "../../assets/images/animated-svg-icons/certificate.svg"
import h4 from "../../assets/images/animated-svg-icons/user.svg"
import { useWebdetailsContext } from '../Context/WebDetails';
import ModalComponent from "../ModalComponent/Modal";
// import { useNewsheadlineContext } from "../Context/NewsHeadline";
// import { useToggleContext } from "../Context/Togglemanagement";



function Highlights() {
  const [open1, setOpen1] = useState(false);
    const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();

    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    // const { newsheadline, fetchNewsheadlineByUrlHandler } =
    // useNewsheadlineContext();

    // const { ToggleopenHandler, isVisible } = useToggleContext();

//   const {isfilter, filterblogs} = useDateContext();  

    // const {backgroundStyle} = useBackgroundContext();

  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
  }, []);

  const header2 = Webdetails?.header2 || "Need FREE Guidence for UPSC?";
  const title2 = Webdetails?.title2 || "Schedule a call with an expert UPSC Mentor";
  return (
    <>


<div className="features-area-2">
  <div className="container">
    <div className="features-box features-style-2 edublink-svg-animate">
      <table style={{ width: "100%", lineHeight: "40px" }}>
        <tr>
          {/* Center-aligned header2 and title2 */}
          <td
            className="title"
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "40px",
              verticalAlign: "middle",
            }}
          >
            <b>{header2}</b>
            <br />
            <span style={{ color: "yellow", fontSize: "25px" }}>{title2}</span>
          </td>

          {/* Right-aligned Query button */}
          <td
            style={{
              textAlign: "right",
              verticalAlign: "middle",
              paddingRight: "10px",
            }}
          >
            <a href="#" onClick={()=>handleOpen1()} className="edu-btn btn-secondary">QUERY NOW <i className="icon-4"></i></a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<ModalComponent
        handleClose={handleClose1}
        open={open1}
        setOpen={setOpen1}
      />


    {/* <div className="features-area-2">
            <div className="container">
                <div className="features-grid-wrap">
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h1} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Live Classes</h5>
                        </div>
                    </div>

                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h4} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Interview</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon certificate">
                            <img className="svgInject" src={h3} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Daily Quiz</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h2} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Test Series</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default Highlights