import { createContext, useState, useContext, useEffect } from 'react';
import Instructor from '../ContentManagement/CourseContent/Instructor/Instructor';
import Overview from '../ContentManagement/CourseContent/Overview/Overview';
import Review from '../ContentManagement/CourseContent/Review/Review';
import { useScrollingManagementContext } from './ScrollingEventManagement';
import { useBranchContext } from './Branchapi';

// Create a context for managing button details
const DetailsButtonContext = createContext();

export function ButtonDetailsProvider({ children }) {
  // State to store button details for multiple components, keyed by component ID
  const [buttonDetailsState, setButtonDetailsState] = useState({});
  const [batchselectedid, setBatchselectedid] = useState('0');
  const [open, setOpen] = useState(false);

  const {branches, loading, error, fetchAllBranchesHandler} = useBranchContext();

  useEffect(() => {
    fetchAllBranchesHandler(); // Fetch branches on component mount
  }, []); 

  const { ScrollingopenHandler } = useScrollingManagementContext();
 
     // Handlers to open and close the modal
     const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => setOpen(false);

  // Define a mapping of button names to batch IDs
  const batchIdMap = {
    'All': '0',
    'Old Rajinder Nagar': '1',
    'Mukherjee Nagar': '2',
    'Pune': '3',
    'Hyderabad': '4',
  };

  // Predefined button configurations for different component types
  const buttonLabels = {
    'BranchDetailsButton': [
      { id: 'Our-Course-tab', label: 'Our Course', target: '#ourcourse', component: <Overview /> },
      { id: 'About-branch-tab', label: 'About Branch', target: '#aboutbranch', component: <Instructor /> }
    ],
    'CourseDetailsButton': [
      { id: 'Overview-tab', label: 'Overview', target: '#overview', component: <Instructor /> },
      { id: 'syllabus-tab', label: 'Syllabus', target: '#syllabus', component: <Overview /> },
      { id: 'What-You’ll-Learn-tab', label: 'What You’ll Learn', target: 'What-You’ll-Learn', component: <Review /> },
      // { id: 'FAQ', label: 'FAQ', target: 'FAQ', component: <Review /> },
      { id: 'Faculty-Member-tab', label: 'Faculty Member', target: '#FacultyMember' , component: <Instructor /> },
      { id: 'Fee Details', label: 'Fee Details', target: '#FacultyMember' , component: <Instructor /> },
      { id: 'BUY NOW', label: 'BUY NOW', target: '#FacultyMember' , component: <Instructor /> }
    ],
    // 'BatchCategory': [
    //   { id: '1', label: 'Old Rajinder Nagar', target: '#1' },
    //   { id: '2', label: 'Mukherjee Nagar', target: '#2' },
    //   { id: '3', label: 'Pune', target: '#3' },
    //   { id: '4', label: 'Hyderabad', target: '#4' },
    // ],

    'BatchCategory': branches,
    'sriCourse': [
      { id: 'csat-tab', label: 'CSAT', target: '#csat' },
      { id: 'two-years-gs-tab', label: '2 Years G.S Course', target: '#two-years-gs' },
      { id: 'mains-mastery-tab', label: 'Mains Mastery Course 2025', target: '#mains-mastery' },
      { id: 'one-year-gs-tab', label: '1 Year G.S Course', target: '#one-year-gs' },
      { id: 'optional-subject-tab', label: 'Optional Subject Course', target: '#optional-subject' },
      { id: 'gs-module-tab', label: 'G.S Module Course', target: '#gs-module' },
    ],
  };

  // Function to update button names based on componentName
  const updateButtonDetailsNames = (componentID, componentName ,defaultLabel) => {
    // Get button details for the given componentName
    const buttons = buttonLabels[componentName];
    
    // Handle invalid componentName
    if (!buttons) {
      console.error(`Component name "${componentName}" is not valid or not defined.`);
      return;
    }

    // Determine the default active button. If no defaultLabel is provided, use the first button.
    const defaultActiveLabel = defaultLabel || (buttons.length > 0 ? buttons[0].label : '');


    // Update state for the specific component ID
    setButtonDetailsState((prevState) => ({
      ...prevState,
      [componentID]: {
        buttonDetailsNames: buttons,
        activeDetailsButton: defaultActiveLabel, // Set default active button
      },
    }));
  };

  // Function to handle setting the active button
  const handleDetailsButtonClick = (componentID, label) => {
    ScrollingopenHandler();
    if(label !== 'BUY NOW' ){
    setButtonDetailsState((prevState) => ({
      ...prevState,
      [componentID]: {
        ...prevState[componentID],
        activeDetailsButton: label,
      },
    }));
}

    // Update batchselectedid based on the label using batchIdMap
    const batchId = batchIdMap[label];
    if (batchId) {
      setBatchselectedid(batchId);
    }

    // if (label !== 'Course Details') {
        // const section1 = document.getElementById("coursedetailsection");
        // const section = document.getElementById("coursebatchsection");
        // if (section1) {
          // section.scrollIntoView({ behavior: "smooth" });
      //     section1.scrollIntoView({ behavior: "smooth" });
      //   }
      // }

      if(label === 'BUY NOW'){
        handleOpen()
      }
  };

  return (
    <DetailsButtonContext.Provider
      value={{
        buttonDetailsState,
        batchselectedid,
        open,
        handleClose,
        updateButtonDetailsNames,
        handleDetailsButtonClick,
      }}
    >
      {children}
    </DetailsButtonContext.Provider>
  );
}

// Hook to use button details context in components
export function useButtonDetailsContext() {
  return useContext(DetailsButtonContext);
}
