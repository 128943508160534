import React from 'react';
// import img from "assets/images/banner/Camning Soon.png"

function Coming_Soon() {
  return (
    <>
                  <div className="section-title section-center">
                    <div style={{padding:"250px"}}>
                    <h2 className="title">Coming Soon</h2>
                    </div>
                    </div>
    </>
  )
}

export default Coming_Soon;