import { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchAllArticlesDate, fetchAllBlogsDate, fetchMoreArticles, fetchMoreArticlesDate, fetchMoreBlogsDate } from '../../Config/api'; 

const DateContext = createContext();

export function DateProvider({ children }) {
  // Default values for start and end date
  const defaultStartDate = "2024-05-01";
  const defaultEndDate = new Date().toISOString().split("T")[0];
    const [filterblogs, setFilterblogs] = useState([]);
    const [filterarticles, setFilterarticles] = useState([]);
    const [isfilter, setIsfilter] = useState(false);
    const [startdate, setStartDate] = useState(defaultStartDate);
    const [enddate, setEndDate] = useState(defaultEndDate);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Detect route changes
    const location = useLocation();

     // Reset state to default when the route changes
     useEffect(() => {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      setIsfilter(false); // Reset filter state
  }, [location.pathname]); // Trigger reset on pathname change


    const handleStartDateChange = (e) => {
      
        setStartDate(e.target.value);
        console.log(e.target.value)
        console.log(startdate)
      };
    
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        console.log(e.target.value)
        console.log(enddate)
      };
    
      const handleSubmitBlog = async (e, s1, e1) => {
        if (e && e.preventDefault) e.preventDefault();
        setLoading(true);
        setIsfilter(true);
        try {
            const data = await fetchAllBlogsDate(s1, e1);
            setFilterblogs(data.data);
            console.log(data);
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
            // setIsfilter(false);
        }
    };

    const handleMoreSubmitBlog = async (s1, e1,limit) => {
      // if (e && e.preventDefault) e.preventDefault();
      setLoading(true);
      setIsfilter(true);
      try {
          const data = await fetchMoreBlogsDate(s1, e1,limit);
          setFilterblogs((prev) => [...prev, ...data.data]);
          console.log(data);
          setError(null);
      } catch (err) {
          console.error("Error fetching branches:", err);
          setError("Failed to load branches");
      } finally {
          setLoading(false);
          // setIsfilter(false);
      }
  };
    

      const handleSubmitarticle = async (e, s1, e1) => {
        if (e && e.preventDefault) e.preventDefault();
        setLoading(true);
        setIsfilter(true);
        try {
            const data = await fetchAllArticlesDate(s1, e1);
            setFilterarticles(data.data); // Set branches data from response
            console.log(data);
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
            // setIsfilter(false);
        }
      };

      const handleMoreSubmitArticle = async (s1, e1,limit) => {
        console.log("i am fetching more article")
        // if (e && e.preventDefault) e.preventDefault();
        setLoading(true);
        setIsfilter(true);
        try {
            const data = await fetchMoreArticlesDate(s1, e1,limit);
            setFilterarticles((prev) => [...prev, ...data.data]);
            console.log(data);
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
            // setIsfilter(false);
        }
    };
  
    return (
      <DateContext.Provider value={{ startdate, enddate, filterblogs, filterarticles, isfilter, loading, error, handleStartDateChange, handleEndDateChange, handleSubmitBlog, handleSubmitarticle, handleMoreSubmitBlog,handleMoreSubmitArticle}}>
        {children}
      </DateContext.Provider>
    );
  }
  
  export function useDateContext() {
    return useContext(DateContext);
  }