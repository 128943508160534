import React, { useEffect, useRef } from "react";
import queryimg from "../../assets/images/query-img.png";
import { useQueryContext } from "../Context/QueryManagement";
import { useWebdetailsContext } from "../Context/WebDetails";

function CustomEnquery() {
 
   // Access QuerySubmitHandler from the context
   const {pagename, QuerySubmitHandler } = useQueryContext();

   const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
   useWebdetailsContext();

   useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
  }, []); 

     // Use a reference to the form to reset it after submission
  const formRef = useRef(null);

    const handleForm = async (e) =>{
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries())
       // Call the QuerySubmitHandler with the form data
    // Call the QuerySubmitHandler with the form data
    const success = await QuerySubmitHandler(
      data.name,
      data.contact,
      data.email,
      data.query,
      pagename, // Replace with the actual pagename if available
      window.location.href // Use current page URL for pagurl
    );

    // If submission is successful, reset the form
    if (success) {
      formRef.current.reset(); // Reset the form to clear input fields
    }
    };

    const Header1 = Webdetails?.header1 || "Header Here";
    const title1 = Webdetails?.title1 || "Title Here";

  return (
    <>
    
    <div className="col-lg-12 mt-4">
                        {/* <div className="contact-form form-style-2 px-5 py-5 animated-gradient-background"> */}
                        <div className="contact-form form-style-2 px-0 pt-0 py-5 animated-gradient-background1" style={{border:"1px solid #ccc", background:"linear-gradient(345deg, #ECECFF,white 60%)"}}>
                            <div className="section-title" style={{background:"#301C6F"}}>
                                <h4 className="title text-center text-white w-100 py-4">
                                  <h4 style={{color:"white"}}>{Header1}</h4>
                                  {/* <img  src={queryimg} alt="" style={{width:"180px"}}/> */}
                                  {/* <img  src={queryimg} style={{mixBlendMode: 'color-burn', width: "180px"}} alt=""/> */}
                                  
                                  {/* Have A Query! */}
                                  
                                  </h4>
                                <p className="px-1 text-white text-center" style={{paddingBottom:"7px"}}>{title1}</p>
                            </div>
                            <div
        className="form-wrapper rnt-contact-form rwt-dynamic-form px-5"
        id="signin"
      >
        <form name="form1" ref={formRef} className="rnt-contact-form rwt-dynamic-form" action="" onSubmit={handleForm} method="post">
          <div className="d-flex justify-content-center">
            {/* <img src={query} style={{ width: "60%" }} /> */}
          </div>
          <div className="form-floating my-3 ">
            <input
              type="text"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc" }}
              // style={{ fontSize: "14px", border: "1px solid rgba(255,255,255,0.2)" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            />
            {/* <input
              type="text"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", color: "white", border: "2px solid rgba(255,255,255,0.5)", background: "transparent" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            /> */}
            <label className="fs-5" for="floatingInputname"><i className="icon-6"></i> Name</label>
          </div>

          <div className="form-floating  my-3">
            <input
              type="email"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="email"
              id="floatingInputnumber"
              placeholder="Email"
            />
            <label for="floatingInputnumber" className="fs-5"><i className="icon-envelope"></i> Email</label>
          </div>
          <div className="form-floating my-3">
            <input
              type="number"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="contact"
              id="floatingInputemail"
              placeholder="Mobile No"
            />
            <label for="floatingInputemail" className="fs-5"><i className="icon-phone"></i> Mobile</label>
          </div>
          <div className="form-floating my-3">
            <textarea
              rows={5}
              cols={30}
              className="form-control py-5 pb-5"
              name="query"
              style={{ fontSize: "14px", minHeight: "100px", border: "1px solid #ccc" }}
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <label for="floatingTextarea" className="fs-5"><i className="icon-81"></i> Query here!!</label>
          </div>
                                              <div className="col-12 text-center">
                                        <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit <i className="icon-4"></i></button>
                                    </div>
        </form>
      </div>
                            {/* <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul> */}
                        </div>
                    </div>
    </>
  );
}

export default CustomEnquery;
