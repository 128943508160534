import React, { useEffect, useState } from "react";
import "../styles/querybutton.scss";
import { useWebdetailsContext } from './Context/WebDetails';
import ModalComponent from "./ModalComponent/Modal";

function FloatingButton() {
  const [open1, setOpen1] = useState(false);
  const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();

    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);


  useEffect(() => {
    fetchWebdetailsByUrlHandler();
    const floatingButtonContainer = document.querySelector('.floating-button-container');

    // Function to reset animations periodically every 30 seconds
    const resetAnimations = () => {
      floatingButtonContainer.style.animation = 'none'; // Remove the animation
      const _ = floatingButtonContainer.offsetHeight; // Trigger reflow to reset the animation
      floatingButtonContainer.style.animation = 'slide-up 1s ease-out forwards, rotate-in 1s ease-out 1s'; // Reapply the animation
    };

    // Reset animations every 30 seconds
    const intervalId = setInterval(resetAnimations, 30000);

    // Add click event listener for the button
    const floatingButton = document.getElementById('floatingButton');
    const handleClick = () => {
      // alert('You clicked the floating button!');
      handleOpen1()
    };
    floatingButton.addEventListener('click', handleClick);

    // Cleanup function to remove listeners and intervals when component unmounts
    return () => {
      clearInterval(intervalId); // Clear the interval
      floatingButton.removeEventListener('click', handleClick); // Remove the click event listener
    };
  }, []);
  const header2 = Webdetails?.header2 || "Need FREE Guidence for UPSC?";
  return (
    <>
    <div className="floating-button-container">
      <button id="floatingButton" className="floating-button">
        {header2} {/* Use the locally defined variable */}
      </button>
    </div>
    <ModalComponent
        handleClose={handleClose1}
        open={open1}
        setOpen={setOpen1}
      />
    </>
  );
}

export default FloatingButton; 
