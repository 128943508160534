import React, { useEffect } from 'react';
import { useButtonDetailsContext } from '../Context/Pagebuttonmanagement';

function ButtonDetailsWithBorder({ DetailscomponentID, DetailscomponentName, defaultLabel }) {
  const {
    buttonDetailsState,
    updateButtonDetailsNames,
    handleDetailsButtonClick,
  } = useButtonDetailsContext();

  // Get the state specific to this component ID
  const { buttonDetailsNames = [], activeDetailsButton = '' } =
    buttonDetailsState[DetailscomponentID] || {};

  useEffect(() => {
    if (!buttonDetailsState[DetailscomponentID]?.buttonDetailsNames) {
      updateButtonDetailsNames(DetailscomponentID, DetailscomponentName, defaultLabel);
    }
  }, [DetailscomponentID, DetailscomponentName, buttonDetailsState, updateButtonDetailsNames]);

  const columnClass = buttonDetailsNames.length === 2 
  ? 'col-lg-3' 
  : buttonDetailsNames.length === 4 
  ? 'col-lg-3 col-md-6 col-sm-12' 
  : buttonDetailsNames.length > 4 
  ? 'col-lg-2 col-md-3 col-sm-6' 
  : 'col-lg-3 col-md-6 col-sm-12';

  return (
    <>
      <ul className="nav px-1" id="myTab" role="tablist">
        {buttonDetailsNames.map((button) => (
          <li className={`px-2 nav-item ${columnClass}`} key={button.id}>
            <button
              className={`custombutton ${activeDetailsButton === button.label ? 'custombuttonactive' : ''} ${
                button.label === 'BUY NOW' ? 'buy-now-button' : ''
              }`}
              id={button.id}
              aria-selected={activeDetailsButton === button.label ? 'true' : 'false'}
              onClick={() => handleDetailsButtonClick(DetailscomponentID, button.label)}
            >
              {button.label}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}

export default ButtonDetailsWithBorder;
