import React, { Suspense, useEffect } from 'react';
// import About from '../About/About';
import Categories from '../Categories/Categories';
import Testimonial from '../Testimonial/Testimonial';
import CTA from '../CTA/CTA';
import Highlights from '../Highlights/Highlights';
import Instructors from '../Instructors/Instructors';
import Offers from '../Offers/Offers';
import Partners from '../Partners/Partners';
import { HeroProvider } from '../Context/Heroapi';
import { AboutProvider } from '../Context/Aboutapi';
import { TestimonialProvider } from '../Context/Testimonialapi';
import { useHomeContext } from '../Context/Homeapi';
import { Link } from 'react-router-dom';
import Testimonial1 from '../Testimonial/Testimonial1';
import { useScrollingManagementContext } from '../Context/ScrollingEventManagement';
import { useWebdetailsContext } from '../Context/WebDetails';
// import Branch from '../Courses/Branch';
const  Batch = React.lazy(() => import("../Batch/Batch"));
const  HeroCard = React.lazy(() => import("../CustomBanner/hero-card"));
const  About = React.lazy(() => import("../About/About"));
const  Branch = React.lazy(() => import("../Courses/BranchHome"));
// const  Testimonial = React.lazy(() => import("../Testimonial/Testimonial"));

function Home() {
  const { Webdetails, Homecontent, loading, error, fetchHomeContentHandler, fetchWebdetailsByUrlHandler} =
  useHomeContext();
  useWebdetailsContext();


  const { ScrollingopenHandler } = useScrollingManagementContext();


  useEffect(()=>{
    fetchHomeContentHandler();
    // fetchWebdetailsByUrlHandler();
  },[])

  const content1 = Homecontent?.content1 || "";
  const content2 = Homecontent?.content2 || "";
  const content3 = Homecontent?.content3 || "";
  const content4 = Homecontent?.content4 || "";
  const backgrounddisplay1 = Homecontent.bgcolor1;
  const backgrounddisplay2 = Homecontent.bgcolor2;
  const backgrounddisplay3 = Homecontent.bgcolor3;
  const backgrounddisplay4 = Homecontent.bgcolor4;
  const hide1 = Homecontent.hide1;
  const hide2 = Homecontent.hide2;
  const hide3 = Homecontent.hide3;
  const hide4 = Homecontent.hide4;


  return (
    <>
    <HeroProvider>
    <Suspense fallback={<p>Loading..</p>}>
    <HeroCard/>
    </Suspense>
    </HeroProvider>
<Highlights/>
<Suspense fallback={<p>Loading..</p>}>
<Batch/> 
</Suspense> 
<AboutProvider>
<Suspense fallback={<p>Loading..</p>}>
<About/> 
</Suspense>
</AboutProvider>
<div className="edu-course-area course-area-1 gap-tb-text" style={{background: "#F2F4F7"}}>
  
<div className='container'>
<Suspense fallback={<p>Loading..</p>}>
            <Branch/>
            </Suspense>
            </div>
            <div className='container'>
            <div className="load-more-btn">
                    <Link to="/course" onClick={()=>ScrollingopenHandler()} className="edu-btn text-white">View More Courses <i className="icon-35"></i></Link>
                </div>
            </div>
            </div>
<TestimonialProvider>
<AboutProvider>
{/* <Suspense fallback={<p>Loading...</p>}> */}
<Categories/>
<Testimonial/>
{/* <Testimonial1/> */}
{/* </Suspense> */} 
</AboutProvider>
</TestimonialProvider>
{/* <div className="edu-course-area course-area-1 gap-tb-text bg-lightenharsh"> */}

{hide1 === 0 && <div className='edu-course-area course-area-1 gap-tb-text'>
  {backgrounddisplay1 === 1 &&
  <div style={{ width: "100%", height: "20px", background: "linear-gradient(to top right, #F2F4F7 50%, #FFFFFF 50%)",
    }}></div>}
  <div className={`col-lg-12 edu-course-area course-area-1 gap-tb-text bg-lighten0${backgrounddisplay1}`}>

 
<div className='col-lg-10 mx-auto py-3' dangerouslySetInnerHTML={{ __html: content1 }} />
  </div>
</div>}


{hide2 === 0 && <div className='edu-course-area course-area-1 gap-tb-text py-0'>
  {backgrounddisplay2 === 1 &&
  <div style={{ width: "100%", height: "20px", background: "linear-gradient(to top right, #F2F4F7 50%, #FFFFFF 50%)",
    }}></div>}
  <div className={`col-lg-12 edu-course-area course-area-1 pt-3 gap-tb-text bg-lighten0${backgrounddisplay2}`}>
  
<div className='col-lg-10 mx-auto py-3' dangerouslySetInnerHTML={{ __html: content2 }} />
  </div>
</div>}

{hide3 === 0 && <div className='edu-course-area course-area-1 gap-tb-text py-0'>
  {backgrounddisplay3 === 1 &&
  <div style={{ width: "100%", height: "20px", background: "linear-gradient(to top right, #F2F4F7 50%, #FFFFFF 50%)",
    }}></div>}
  <div className={`col-lg-12 edu-course-area course-area-1 gap-tb-text bg-lighten0${backgrounddisplay3}`}>
  
<div className='col-lg-10 mx-auto py-3' dangerouslySetInnerHTML={{ __html: content3 }} />
  </div>
</div>}

{hide4 === 0 && <div className='edu-course-area course-area-1 gap-tb-text py-1'>
  {backgrounddisplay4 === 1 &&
  <div style={{ width: "100%", height: "20px", background: "linear-gradient(to top right, #F2F4F7 50%, #FFFFFF 50%)",
    }}></div>}
  <div className={`col-lg-12 edu-course-area course-area-1 gap-tb-text bg-lighten0${backgrounddisplay4}`}>
  
<div className='col-lg-10 mx-auto py-3' dangerouslySetInnerHTML={{ __html: content4 }} />
  </div>
</div>}

{/* </div> */}
<CTA/>
{/* <Instructors/> */}
    </>
  )
}

export default Home;