import React from 'react';
import blogimg from "../../../assets/images/blog/blog-25.jpg"
import { Link } from 'react-router-dom';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function BlogCard({blogs}) {
  const { ScrollingopenHandler } = useScrollingManagementContext();
  
  return (
    <>
    {blogs.map((post, index) => (
    <div className="edu-blog blog-style-list" onClick={()=>ScrollingopenHandler()} key={index}>
                            <div className="inner">
                                <div className="thumbnail" style={{width: "45%", display: "flex" , alignItems:"flex-start" , flexDirection:"column"}}>
                                    <Link to={`/blog/${post.url}/`}>
                                        <img src={post?.pic || blogimg} alt="Blog Images responsive-image"/>
                                    </Link>
                                    {/* <ul className="blog-meta" style={{padding:"5px 0px"}}>
                                        <li className='text-success custom-badge2' style={{fontSize:"11px"}}><i className="icon-27 text-success"></i>{post.blogpostdate}</li>
                                        <li><i className="icon-28"></i>View 09</li>
                                    </ul> */}
                                </div>
                                <div className="content">
                                    <div>
                                    <h5 className="title"><Link to={`/blog/${post.url}/`}>{post.title}</Link></h5>
                                    </div>
                                    <div style={{display:"flex" , flexDirection:"row-reverse" , gap: "10px"}}>
                                      <div className='text-success custom-badge2' style={{fontSize:"9px" , height:"25px" }}>{post.blogpostdate}</div>
                                      <div style={{fontSize:"12px"}}><i className="icon-eye" style={{fontSize: "16px", color: "orangered"}}></i>View 09</div>
                                    </div>
                                    <p style={{textAlign:"justify"}}>{post.metadescription}</p>
                                </div>
                            </div>
                        </div>
    ))}
    </>
  )
}

export default BlogCard;