import React, { Suspense} from 'react';
// import Branch from './Branch';
const  Branch = React.lazy(() => import("./Branch"));

function Allcoursecard() {
  return (
    <>
    <div className="edu-course-area course-area-1 gap-tb-text bg-lightenharsh">
            <div className="container">
            <Suspense fallback={<p>This is Loading. branch</p>}>
            <Branch/>
            </Suspense>
            </div>
        </div>
    
    </>
  )
}

export default Allcoursecard;