import React from 'react';
import { Link } from 'react-router-dom';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function SingleNabar1({submenu, title}) {
   const {isVisible, TogglecloseHandler} = useToggleContext();
   const { ScrollingopenHandler } = useScrollingManagementContext();
  return (
    <>
     <ul className={`submenu custom-single-drop ${isVisible !== "hide" ? '' : 'hidden-submenu'}`} onClick={()=>ScrollingopenHandler()}> 
    {submenu.map((course,index)=>(
     <li key={index} className=''><Link to={`/${course.linkurl}`} onClick={()=>TogglecloseHandler()}><span className={title === 2 || title === 5 ? 'course-level bg-primary text-white p-2 py-1 rounded-circle' : 'course-level bg-success text-white p-2 py-1 rounded-circle'}><i className='icon-20 fs-6'></i></span> &nbsp; {course.title}</Link></li>
    ))}    
    </ul>
    </>
  )
}

export default SingleNabar1;