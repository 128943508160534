import React, { useEffect, useState, Suspense } from "react";
import { Modal, Box, Button, Typography } from '@mui/material';
// import "../../fonts.css"
// import "../../App.css";
import Courses from "../Card/Coursescard";
import Enquery from "../Form/Enquery";
import { useBlocker, useParams } from "react-router-dom";
import { useBranchContext } from "../Context/Branchapi";
import img1 from "../../assets/images/others/shape-81.png";
import img2 from "../../assets/images/others/shape-65.png";
import img3 from "../../assets/images/cta/shape-08.png";
import img4 from "../../assets/images/others/shape-79.png";
import img5 from "../../assets/images/course/course-12.jpg";
import img6 from "../../assets/images/course/course-46.png";
import img7 from "../../assets/images/svg-icons/books.svg";
import { useBackgroundContext } from "../Context/BackgroundChange";
import Coursecardlarge from "../Card/Coursecardlarge";
import Branch from "../Courses/Branch";
import CustomEnquery from "../Form/CustomEnquery";
import { useBlogsContext } from "../Context/Blogsapi";
import PostCard from "../Card/BlogCard/PostCard";
import SEO from "../SEO/SEO";
import { useInterviewContext } from "../Context/Interviewapi";
import BlogCard from "../Card/BlogCard/BlogCard";
import t1 from "../../assets/images/testimonial/testimonial-01.png"
import Instructors from "../Instructors/Instructors1";
const  Keywords = React.lazy(() => import("../Keywords/keywords"));

function TestimonialDetails() {
  return (
    <>
    <div className="section-title section-center pt-5" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
      {/* <span className="pre-title">Popular Courses</span> */}
      <h2 className="title">Testimonial</h2>
      <span className="shape-line"><i className="icon-19"></i></span>
    </div>
      <section className="edu-section-gap course-details-area" style={{padding:"0px 0px 120px"}}>
        <div className="container">
          <div className="row">
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
              <div className="col-lg-3 p-3">
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={t1} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>At our educational institute, we take immense pride in the success stories of our students. Their journeys are a testament to our commitment to providing quality education and fostering personal growth. Through their words, you'll discover how our programs have shaped their skills, confidence, and career paths.</p>
                                        <h6 className="title">Aniket</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>
            </div>
          </div>
      </section>

    </>
  );
}

export default TestimonialDetails;
