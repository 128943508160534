import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBranchContext } from '../../Context/Branchapi';
import { useToggleContext } from '../../Context/Togglemanagement';
import { useCourseContext } from '../../Context/Courseapi';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';
import { useBranchwiseidContext } from '../../Context/BranchwiseCourse';

function SingleNabar() {
  // const { branches, loading, error, fetchAllBranchesHandler } = useBranchContext();
  const {courses, loading, error, fetchAllCoursesHandler} = useCourseContext();
  const {isVisible, TogglecloseHandler} = useToggleContext();
  const { ScrollingopenHandler } = useScrollingManagementContext();

  const { branchwiseCourseidhandlerDefault  } =
  useBranchwiseidContext();

  useEffect(() => {
    // fetchAllBranchesHandler(); // Fetch branches on component mount
    fetchAllCoursesHandler(); // Fetch branches on component mount
  }, []);


  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <>
     <ul className={`submenu custom-single-drop ${isVisible !== "hide" ? '' : 'hidden-submenu'}`} onClick={()=>ScrollingopenHandler()}>
     {courses.map((branch) => (
                <li onClick={()=>branchwiseCourseidhandlerDefault()} key={branch.branchurl}>
                    <Link to={`/course-details/${branch.courseurl}`} onClick={()=>TogglecloseHandler()}><span className='course-level bg-primary text-white p-2 py-1 rounded-circle' style={{width: "50px", height: "50px"}}><i className='icon-20 fs-6'></i></span> &nbsp; {branch.coursename}</Link>
                </li>
            ))}
                                            </ul>
    </>
  )
}

export default SingleNabar;