import React from 'react';
import img9 from "../../../assets/images/blog/small-post-01.jpg";
import { Link } from 'react-router-dom';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';

function PostCard({blogs, branchName}) {
    const { ScrollingopenHandler } = useScrollingManagementContext();
    let newArray = blogs.slice(0, 10);

    // Filter objects with id = 2
   let filteredArray = newArray.filter(item => item.title !== branchName);
  return (
    <>
    {filteredArray.map((post, index) => (
    <div className="latest-post" onClick={()=>ScrollingopenHandler()} key={index}>
                                            <div className="thumbnail" style={{width: "40%"}}>
                                                <Link to={`/blog/${post.url}/`}>
                                                    <img src={post?.pic || img9} alt="Blog Images responsive-image1" />
                                                </Link>
                                            </div>
                                            <div className="post-content">
                                                <h6 className="title"><Link to={`/blog/${post.url}/`}>{post.title}</Link></h6>
                                                <ul className="blog-meta" style={{float:"inline-end"}}>
                                                    <li className='text-success custom-badge2'><i className="icon-27 text-success"></i>{post.blogpostdate}</li>
                                                </ul>
                                            </div>
                                        </div>
    ))}
    </>
  )
}

export default PostCard;