import React from 'react';
import imgcourse from "../../assets/images/blog/blog-01.png";
import imglogo from "../../assets/images/course/course-111.jpg";
import { Link, useNavigate } from 'react-router-dom';
import { useScrollingManagementContext } from '../Context/ScrollingEventManagement';

function Coursecardlarge({courses, category}) {
  const navigate = useNavigate();
  const { ScrollingopenHandler } = useScrollingManagementContext();
  

  function link(url){
    navigate(url)
  }

  return (
    <>
{/* {courses.map((course) => (
          <div key={course.pic} onClick={()=>ScrollingopenHandler()} className="col-12 col-xl-4 my-2 col-lg-6 col-md-6">
            <div className="edu-course course-style-3 course-style-5 inline" data-tipped-options={`inline: 'inline-tooltip-1'`}>
              <div className="inner">
                <div className="thumbnail">
                  <Link to={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} onError={(e) => {
                                        e.target.src = imglogo;
                                    }} alt="Course Meta"/>
                  </Link>
                </div>
                <div className="content custom-content">
                  <br/>
                  <Link to={`/course-details/${course.courseurl}`}><span className="course-level text-success fw-bold fs-5 px-0 py-2 pb-0" style={{backgroundColor: "transparent", height: "45px"}}><span><img src='https://admin.sriramsias.com/images/batchicon.png' alt=''/></span> &nbsp;{course.coursename}</span></Link>
                  <p className='custom-paragraph1 text-dark fs-5' style={{textAlign: "justify"}}>{course.metadescription}</p>
                  <ul className="course-meta d-flex justify-content-end">
                    <li><Link className='btn-danger p-2 px-4 short-custom-btn1 fs-6 fw-bold custom-padding' to={`/course-details/${course.courseurl}`}>View Details</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))} */}

{courses.map((course) => (
<Link to={`/course-details/${course.courseurl}`} onClick={()=>ScrollingopenHandler()} className="col-12 col-xl-6 my-3 col-lg-6 col-md-6">
                        <div class="edu-course course-style-3 course-box-shadow">
                            <div class="inner">
                                <div class="thumbnail">
                                <Link to={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} onError={(e) => {
                                        e.target.src = imgcourse;
                                    }} alt="Course Meta"/>
                  </Link>
                  <div class="time-top">
                                        <span class="duration"><i class="icon-61"></i>{course.duration}</span>
                                    </div>
                                </div>
                                <div class="content custom-content">
                                  <div className='d-flex'>
                                <span class="course-level custom-badge d-flex justify-content-center align-items-center py-2">{course.cname? course.cname : "SRIRAM's IAS"}</span>
                                  </div>
<Link to={`/course-details/${course.courseurl}`}><span className="course-level fw-bold fs-3 px-0 py-2 pb-0" style={{backgroundColor: "transparent", height: "45px", fontFamily: "Arial, sans-serif",  color: "#000000"  }}>{course.coursename}</span></Link>
<p className="custom-paragraph1 text-dark" style={{textAlign: "justify", marginBottom: "", lineHeight: "4vh"}}>{course.metadescription}</p>
                                   
                                    <div className="course-rating m-0">
                                        <div className="rating">
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                            <i className="icon-23 fs-4"></i>
                                        </div>
                                    </div>
                                    <div class="read-more-btn">
                                    <ul className="course-meta mt-0 d-flex justify-content-end">
                    <li>
                    <Link class="edu-btn btn-small btn-secondary" to={`/course-details/${course.courseurl}`}>View Details<i class="icon-4 text-white"></i></Link>
</li>
                  </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
))}
    </>
  )
}

export default Coursecardlarge;