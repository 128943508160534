import React, { useState } from 'react';
import { ButtonProvider } from '../Context/Buttonmanagement';
import CivilTopSection from '../CivilTopSection/CivilTopSection';

function AllSriCourse() {
    const [hasUpdated, setHasUpdated] = useState(false);

      // Banner Content For CivilAbout Page
  const data = {
    subacademy: 'OUR',
    academyName: `Branches »»`,
    // userImage: userImage,
    userName: 'Priya Rathore',
    // Image: aboutImage
  };

  const componentName = 'sriCourse';
  return (
    <>
            <section className='civilabout'>
            <ButtonProvider>
        <CivilTopSection
          hasUpdated={hasUpdated}
          setHasUpdated={setHasUpdated}
          componentName={componentName}
          subacademy={data.subacademy}
          academyName={data.academyName}
          // userImage={data.userImage}
          userName={data.userName}
          // Image={data.Image}
        />
        </ButtonProvider>
        </section>
    </>
  )
}

export default AllSriCourse;