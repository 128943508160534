import { createContext, useState, useContext } from 'react';
import { fetchAllCourses, fetchCourseByUrl, fetchCourseByUrlBrach } from '../../Config/api';
import { useNavigate } from 'react-router-dom';

const CourseContext = createContext();

export function CourseProvider({ children }) {
    const [courses, setCourses] = useState([]);
    const [coursesDetails, setCoursesDetails] = useState({});
    const [BatchbyCourse, setBatchbyCourse] = useState([]);
    const [facultybyCourse, setFacultybyCourse] = useState([]);
    const [branchbyCourse, setbranchbyCourse] = useState([]);
    const [packagebyCourse, setpackagebyCourse] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    // const navigate = useNavigate();
      // Fetch all branches data
      const fetchAllCoursesHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllCourses();
            setCourses(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    }; 

        // Fetch single branch data based on URL 
        const fetchCourseByUrlHandler = async (courseurl) => {
          console.log(courseurl+ "in branch conext api")
          setLoading(true);
          try {
              const data = await fetchCourseByUrl(courseurl);
                setCoursesDetails(data.data.course)
                setBatchbyCourse(data.data.packages.batches)
                setFacultybyCourse(data.data.faculty)
                setbranchbyCourse(data.data.branchdata)
                setpackagebyCourse(data.data.packages)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              // navigate("/notfound")
              return null;
          } finally {
              setLoading(false);
          }
      };

        // Fetch single branch data based on URL 
        const fetchCourseidByUrlHandler = async (courseurl, id) => {
          console.log(courseurl+ "in branch conext api")
          setLoading(true);
          try {
              const data = await fetchCourseByUrlBrach(courseurl, id);
                setCoursesDetails(data.data.course)
                setBatchbyCourse(data.data.packages.batches)
                setFacultybyCourse(data.data.faculty)
                setbranchbyCourse(data.data.branchdata)
                setpackagebyCourse(data.data.packages)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              // navigate("/notfound")
              return null;
          } finally {
              setLoading(false);
          }
      };
  
    return (
      <CourseContext.Provider value={{ courses, coursesDetails, BatchbyCourse, facultybyCourse, branchbyCourse, packagebyCourse, loading, error, fetchAllCoursesHandler, fetchCourseByUrlHandler, fetchCourseidByUrlHandler}}>
        {children}
      </CourseContext.Provider>
    );
  }
  
  export function useCourseContext() {
    return useContext(CourseContext);
  }